import React, { FC, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTheme } from 'styled-components'

import { Props } from './Trustpilot'
import { WidgetType } from './types'

declare global {
  interface Window {
    Trustpilot: any
  }
}

window.Trustpilot = window.Trustpilot || {}

export const TrustBox: FC<Pick<Props, 'widgetType' | 'themeStyle'>> = ({
  widgetType,
  themeStyle,
}) => {
  const theme = useTheme()

  const trustBoxRef = useRef<HTMLDivElement>(null)

  const [templateId, setTemplateId] = useState<string>('539adbd6dec7e10e686debee')
  const [styleHeight, setStyleHeight] = useState<string>('500px')
  const [dataStars, setDataStars] = useState<string>('4,5')

  useEffect(() => {
    if (
      'Trustpilot' in window &&
      typeof window.Trustpilot.loadFromElement === 'function'
    )
      window.Trustpilot.loadFromElement(trustBoxRef.current, true)
  }, [])

  useEffect(() => setWidgetAttributes(widgetType), [widgetType])

  const setWidgetAttributes = (widgetType: WidgetType) => {
    switch (widgetType) {
      case 'horizontal':
        setTemplateId('5406e65db0d04a09e042d5fc')
        setStyleHeight('28px')
        break

      case 'carousel':
        setTemplateId('53aa8912dec7e10d38f59f36')
        setStyleHeight('140px')
        setDataStars('4,5')
        break

      case 'micro-star':
        setTemplateId('5419b732fbfb950b10de65e5')
        setStyleHeight('24px')
        break

      default:
      case 'grid':
        setTemplateId('539adbd6dec7e10e686debee')
        setStyleHeight('500px')
        setDataStars('4,5')
        break

      case 'mini':
        setTemplateId('53aa8807dec7e10d38f59f32')
        setStyleHeight('150px')
        break
    }
  }

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
      </Helmet>

      <div
        ref={trustBoxRef}
        className="trustpilot-widget"
        data-locale={`${process.env.GATSBY_LANG}-${process.env.GATSBY_COUNTRY}`}
        data-template-id={templateId}
        data-businessunit-id={
          process.env.GATSBY_COUNTRY === 'IT'
            ? '5bf7ed6d996c850001ba27c0'
            : '570e5b060000ff00058b7795'
        }
        data-font-family="Inter"
        data-text-color={
          themeStyle === 'dark'
            ? theme.tokens.text.paragraph.reversed
            : theme.tokens.text.paragraph.regular
        }
        data-style-height={styleHeight}
        data-style-width="100%"
        data-theme={themeStyle}
        data-stars={dataStars}
      >
        <a
          href="https://www.trustpilot.com/review/moneyfarm.com"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
    </>
  )
}
